/*
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

interface IconWithTooltipProps {
  Icon: React.ComponentType<SvgIconProps>;
  height?: number;
  iconColor: string;
  tooltip: string;
  width?: number;
}

const IconWithTooltip = (props: IconWithTooltipProps) => {
  const { height, Icon, iconColor, tooltip, width } = props;

  return (
    <Tooltip title={tooltip}>
      <Icon
        style={{
          color: iconColor,
          height: height || 16,
          width: width || 16,
        }}
      />
    </Tooltip>
  );
};

export default IconWithTooltip;
