/*
 * Copyright 2018 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export enum LocalStorageKey {
  navbarCollapsed = 'navbarCollapsed',
}

export class LocalStorage {
  public static hasKey(key: LocalStorageKey): boolean {
    return localStorage.getItem(key) !== undefined;
  }

  public static isNavbarCollapsed(): boolean {
    return localStorage.getItem(LocalStorageKey.navbarCollapsed) === 'true';
  }

  public static saveNavbarCollapsed(value: boolean): void {
    localStorage.setItem(LocalStorageKey.navbarCollapsed, value.toString());
  }
}
